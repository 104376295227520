/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import "./Space.scss";
import { ReactComponent as Plus } from "../../assets/icon/plus.svg";
import { ReactComponent as Replace } from "../../assets/icon/exchange.svg";
import { ReactComponent as Map } from "../../assets/icon/map.svg";
import { ReactComponent as Floor } from "../../assets/icon/floor.svg";
import {
  getImageForMinimap,
  getMiniMap,
  getPoi,
} from "../../crud/showcase/showcase";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Arrow } from "../../assets/icon/direction.svg";
import { ReactComponent as ZoomIn } from "../../assets/icon/zoom_in.svg";
import { ReactComponent as ZoomOut } from "../../assets/icon/zoom_out.svg";
import { ReactComponent as Reset } from "../../assets/icon/reset.svg";
import {
  hideLoaderAction,
  showLoaderAction,
  showSimpleModalAction,
} from "../../redux/actions";
import { removeAll } from "../../helpers/poiFunction";
import ModalPoint from "../ModalPoint/ModalPoint";
import { CustomTooltip } from "../CustomTooltip/CustomTooltip";
import classNames from "classnames";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const MinimapSpaceBtn = styled("button", {
  shouldForwardProp: (prop) => prop !== "color",
})(({ color }) => ({
  "&:hover": {
    background: "rgba(0, 0, 0, 0.8)",
    color,
    fill: color,
  },
  "&:active": {
    background: "rgba(0, 0, 0, 1)",
    color,
    fill: color,
  },
}));

const SpaceFloor = styled("span", {
  shouldForwardProp: (prop) => prop !== "color",
})(({ color }) => ({
  "&:hover": {
    color,
  },
  "&.space_floor--active": {
    color,
  },
}));

const sdkVersion = "3.5";

const Space = ({
  content,
  space,
  point,
  setMatterport,
  menu,
  changeContent,
  setRemoveActiveLink,
}) => {
  const isFirstTimeOpenMinimap = useRef(true);
  const [isMainFrame, setIsMainFrame] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [floorShow, setFloorShow] = useState(true);
  const [showCase, setShowCase] = useState(null);
  const [isDisabledMinimap, setIsDisabledMinimap] = useState(false);
  const [floor, setFloor] = useState([]);
  const [currFloor, setCurrFloor] = useState(null);
  const [findFloor, setFindFloor] = useState(null);
  const [minimapImg, setMinimapImg] = useState(null);
  const [listPoi, setListPoi] = useState([]);
  const [currentPoi, setCurrentPoi] = useState(null);
  const [mapImg, setMapImg] = useState(null);
  const [pose, setPose] = useState(null);
  const sweeps = useRef(null);
  const [floorSweeps, setFloorSweeps] = useState(null);
  const [floorPoi, setFloorPoi] = useState(null);
  const [isSpaceLoad, setIsSpaceLoad] = useState(false);
  const dispatch = useDispatch();
  const resetZoom = useRef();
  const zoomRef = useRef();
  const ref = useRef();
  const { t } = useTranslation();
  const color = useSelector((state) => state.profile.color);
  const matterportSdkKey = useSelector(
    (state) => state.profile.matterportSdkKey
  );
  const showMinimapClassnames = classNames({
    "space-show-minimap space-show-minimap--floor": true,
    "space-show-minimap--active": floorShow,
  });

  const removeActive = () => {
    changeContent(false);
    setRemoveActiveLink(true);
  };

  const handleOpenMinimap = () => {
    setIsOpen((prev) => !prev);
    const bodyWidth = document.body.clientWidth <= 576;
    if (bodyWidth) {
      resetZoom.current.click();
      setIsMainFrame(true);
    }
  };

  const handleMain = (e) => {
    resetZoom.current.click();
    setIsMainFrame((prev) => !prev);
  };

  useEffect(() => {
    if (showCase) {
      showCase?.App?.state?.subscribe(function (appState) {
        if (appState.phase === showCase.App.Phase.PLAYING) {
          setIsSpaceLoad(true);
        }
      });
    }
  }, [showCase]);
  useEffect(() => {
    if (
      space?.showMinimap &&
      isFirstTimeOpenMinimap &&
      (minimapImg?.preview || mapImg)
    ) {
      setTimeout(() => {
        setIsOpen(false);
      }, 500);
      isFirstTimeOpenMinimap.current = false;
    }
  }, [space, mapImg, minimapImg?.preview]);
  const getPoint = (mpSdk) => {
    let idSpace = space?.id;
    if (!Number.isInteger(idSpace) || !space?.visibility?.poisVisibility)
      return;
    dispatch(showLoaderAction());
    getPoi(idSpace)
      .then((res) => {
        // filter - remove all enable poi
        function filterPoi(poi) {
          //Always null i remove when add space all poi
          poi.matterPortId = null;
          poi.checked = true;
          return poi.enabled === true;
        }

        let data = res.data.list.filter(filterPoi);
        return removeAll(mpSdk, data);
      })
      .then((arr) => {
        const setPoi = arr.map((item) => {
          item.isShowOnMinimap = true;
          return item;
        });
        setListPoi(arr);
        point(setPoi);
      })
      .catch((err) => {
        console.log(err);
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        if (statusCode !== 401) {
          dispatch(showSimpleModalAction({ title: "Error", text: message }));
        }
      })
      .finally(() => {
        dispatch(hideLoaderAction());
      });
  };
  const getFloorSpace = () => {
    let idSpace = space?.id;
    if (!Number.isInteger(idSpace) || !space?.userInterface?.floorsEnabled)
      return;
    dispatch(showLoaderAction());
    getMiniMap(idSpace)
      .then((res) => {
        let miniMap = res.data?.map((floor) => {
          floor.active = false;
          return floor;
        });
        setFloor(miniMap);
      })
      .catch((err) => {
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: "Error", text: message }));
      })
      .finally(() => {
        dispatch(hideLoaderAction());
      });
  };

  const getImgForMinimap = (id) => {
    if (!id) {
      setMinimapImg(null);
      setIsMainFrame(true);
      //setIsOpen(true)
      setIsDisabledMinimap(true);
      return;
    }
    dispatch(showLoaderAction());
    getImageForMinimap(id)
      .then((res) => {
        if (res.data?.size === 0) {
          setMinimapImg(null);
          return;
        }
        const reader = new window.FileReader();
        reader.readAsDataURL(res.data);
        console.log("miniMap2", reader);
        reader.onload = function () {
          let result = { preview: reader.result };
          setMinimapImg(result);
        };
      })
      .catch((err) => {
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: "Error", text: message }));
        setMinimapImg(null);
        setIsMainFrame(true);
        //setIsOpen(true)
        setIsDisabledMinimap(true);
      })
      .finally(() => {
        dispatch(hideLoaderAction());
      });
  };
  // get all sweeps
  useEffect(() => {
    if (showCase !== null) {
      showCase.Model.getData()
        .then(function (model) {
          sweeps.current = model.sweeps;
        })
        .catch(function (error) {
          // Model data retrieval error.
        });
    }
  }, [showCase]);
  const mobileChangeViewWhenSweep = () => {
    const bodyWidth = document.body.clientWidth <= 576;
    const isNotMainFram = !isMainFrame;
    if (isNotMainFram && bodyWidth) {
      handleMain();
    }
  };
  const gotToSweep = async (sweep) => {
    const rotation = { x: pose.rotationOriginal.x, y: pose.rotationOriginal.y };
    const transition = showCase.Sweep.Transition.FLY;
    const transitionTime = 2000; // in milliseconds
    mobileChangeViewWhenSweep();
    try {
      await showCase.Sweep.moveTo(sweep.sid, {
        rotation: rotation,
        transition: transition,
        transitionTime: transitionTime,
      });
    } catch (e) {
      debugger;
    }
  };

  const goToPoi = async (poi) => {
    let sidPoi = poi.matterPortId;
    let find = listPoi.find((point) => point.id === poi.id);
    setCurrentPoi(find);
    try {
      await showCase.Mattertag.navigateToTag(
        sidPoi,
        showCase.Mattertag.Transition.FLY
      );
    } catch (e) {
      debugger;
    }
  };

  const percentage = (value, totalValue) => {
    return (100 * value) / totalValue;
  };

  const parseToProcent = (minimapData, floors) => {
    const currentFloor = floors | currFloor;
    if (minimapData === null || minimapData === undefined) return;
    const currSettingMinimap = minimapData[0];
    if (!currSettingMinimap) return;
    let findItem = floor.find((item) => item.floor === currentFloor);
    setFindFloor(findItem);
    let sortSweeps = sweeps?.current
      ?.filter((sweep) => sweep.floor + 1 === currentFloor)
      .map((sweep) => {
        let px =
          ((sweep.position.x - currSettingMinimap.image_origin_x) *
            currSettingMinimap.resolution_ppm) /
          currSettingMinimap.width;
        px = px * 100 * (findItem?.setting?.width || 1) + "%";
        let py =
          ((sweep.position.z * -1 - currSettingMinimap.image_origin_y) *
            currSettingMinimap.resolution_ppm) /
          currSettingMinimap.height;
        py = py * 100 * (findItem?.setting?.height || 1) + "%";
        sweep.px = {
          right: px,
          bottom: py,
        };
        return sweep;
      });
    setFloorSweeps(sortSweeps);
    let sortPoi = listPoi
      ?.filter((poi) => poi.floor === currentFloor)
      .map((poi) => {
        let px =
          ((poi.x - currSettingMinimap.image_origin_x) *
            currSettingMinimap.resolution_ppm) /
          currSettingMinimap.width;
        px = px * 100 * (findItem?.setting?.width || 1) + "%";
        let py =
          ((poi.z * -1 - currSettingMinimap.image_origin_y) *
            currSettingMinimap.resolution_ppm) /
          currSettingMinimap.height;
        py = py * 100 * (findItem?.setting?.height || 1) + "%";
        poi.px = {
          right: px,
          bottom: py,
        };
        return poi;
      });
    setFloorPoi(sortPoi);
  };

  function formatImageUrl(image) {
    if (!image.startsWith("http://") && !image.startsWith("https://")) {
      return `https://${image}`;
    }
    if (image.startsWith("http://")) {
      return image.replace("http://", "https://");
    }
    return image;
  }

  const getImgFloor = (floors) => {
    const currentFloor = floors | currFloor;
    const findFloor = floor?.find((item) => item.floor === currentFloor);
    if (!findFloor) return;
    const image = findFloor.image;
    setMapImg(formatImageUrl(image));
  };
  //Every time when is true we dont have a img floor plan and need render minimap
  useEffect(() => {
    if (currFloor && typeof currFloor == "number") {
      parseToProcent(space?.minimapSettings);
      getImgFloor(undefined);
    }
    if (
      (currFloor === "reset" || currFloor === null) &&
      !mapImg &&
      !mapImg?.preview
    ) {
      parseToProcent(space?.minimapSettings, 1);
      getImgFloor(1);
    }
  }, [currFloor, space, listPoi, floor]);
  useEffect(() => {
    if (ref && space !== null) {
      async function onshowcaseConnect(mpSdk) {
        setShowCase(mpSdk);
        setMatterport(mpSdk);
        try {
          const modelData = await mpSdk.Model.getData();
          getPoint(mpSdk);

          // const initialSweep = modelData.sweeps[0];
          // if (initialSweep) {
          //   setTimeout(() => gotToSweep(initialSweep), 2000);
          // }
        } catch (e) {}
      }

      const handlePlaySpace = async () => {
        const iframe = document.getElementById("showcase-iframe");
        try {
          const mpSdk = await window.MP_SDK.connect(
            iframe,
            matterportSdkKey, // key
            sdkVersion // Unused but needs to be a valid string
          );
          onshowcaseConnect(mpSdk);
        } catch (e) {
          console.error("handlePlaySpace", e);
        }
      };

      handlePlaySpace();
    }
  }, [ref, space, matterportSdkKey]);
  useEffect(() => {
    getFloorSpace();
  }, [space?.id]);
  //Change mode for Meas
  useEffect(() => {
    if (!showCase) return;
    else if (showCase && content?.text === "Measurements") {
      showCase?.Measurements.toggleMode(true).then(() => {
        console.log("measurement mode is now active");
      });
    } else {
      showCase?.Measurements.toggleMode(false).then(() => {
        console.log("measurement mode is now active");
      });
    }
  }, [showCase, content]);
  //Change mode for 3d
  useEffect(() => {
    if (!showCase) return;
    else if (showCase && content?.text === "3D") {
      const mode = showCase?.Mode?.Mode?.DOLLHOUSE;
      const transition = showCase?.Mode?.TransitionType?.FLY;
      showCase?.Mode?.moveTo(mode, {
        transition: transition,
      }).then(function (nextMode) {});
    } else {
    }
  }, [showCase, content]);
  //if active Content hide curr poi
  useEffect(() => {
    if (content) {
      setCurrentPoi(null);
    }
  }, [content]);

  //useEffect to observable floor
  useEffect(() => {
    if (showCase?.Floor) {
      observableFloor();
      startListenPose();
    }
  }, [showCase?.Floor, floorSweeps]);
  const observableFloor = () => {
    showCase?.Floor.current.subscribe(function (currentFloor) {
      if (currentFloor.sequence === -1) {
        setCurrFloor("reset");
      } else if (currentFloor.sequence === undefined) {
        if (currentFloor.id === undefined) {
        } else {
        }
      } else {
        let currFloor = currentFloor.sequence + 1;
        setCurrFloor(currFloor);
      }
    });
  };
  const startListenPose = () => {
    showCase?.Camera?.pose?.subscribe(function (pose) {
      if (space === null) return;
      const findSweep = floorSweeps?.find((sweep) => sweep.uuid == pose.sweep);
      // console.log(findSweep)
      // const getResourse = space?.minimapSettings[0]
      // let px =
      //   ((pose.position.x - getResourse.image_origin_x) *
      //     getResourse.resolution_ppm) /
      //   getResourse.width
      // px = px * 100 + '%'
      // let py =
      //   ((pose.position.z * -1 - getResourse.image_origin_y) *
      //     getResourse.resolution_ppm) /
      //   getResourse.height
      // py = py * 100 + '%'
      let initial = pose.rotation.y;
      if (Math.sign(pose.rotation.y) === -1) {
        //initial += 180
        if (Math.abs(initial) >= 90) {
          initial = Math.abs(initial) - 90;
        } else {
          initial = 270 + Math.abs(initial);
        }
      } else {
        if (Math.abs(initial) >= 90) {
          initial = 180 - Math.abs(initial) + 90;
        } else {
          initial = 270 - Math.abs(initial);
        }
      }
      setPose({
        left: findSweep?.px?.right,
        bottom: findSweep?.px?.bottom,
        rotation: initial,
        rotationOriginal: pose.rotation,
      });
    });
  };
  // change state for active floor tabs
  useEffect(() => {
    if (floor?.length > 0 && currFloor) {
      if (currFloor === "reset") {
        let update = floor
          .map((item) => {
            item.active = false;
            return item;
          })
          .reverse();
        setFloor(update);
      } else {
        let update = floor.map((item) => {
          if (item.floor === currFloor) {
            item.active = true;
            return item;
          } else {
            item.active = false;
            return item;
          }
        });
        let findItem = floor.find((item) => item.floor === currFloor);
        //checkIsActiveMinimap(findItem)
        getImgForMinimap(findItem?.minimapId);
        setFloor(update);
      }
    }
  }, [currFloor]);

  const handleChangeFloor = (e, item) => {
    dispatch(showLoaderAction());
    let moveToFloor = item.floor - 1;
    showCase.Floor.moveTo(moveToFloor)
      .then((res) => {})
      .finally(() => {
        dispatch(hideLoaderAction());
      });
  };
  const spaceUrlCreator = () => {
    if (space !== null) {
      const {
        autoplay,
        guidedTourDelay,
        help,
        highlightReelLaunching,
        newTab,
        quickstart,
      } = space.launching;
      const { callToAction, loop, pan, title } = space.guidedTours;
      const { floorsEnabled, scrollWheel, zoomDisabled } = space.userInterface;
      const {
        brandVisibility,
        conferenceCallVisibility,
        dollhouseViewVisibility,
        floorplanVisibility,
        guidedTourButtonsVisibility,
        highlightReelVisibility,
        pinsVisibility,
        poisVisibility,
        portalVisibility,
        removeInfoPanelVisibility,
        shareButtonVisibility,
        vrButtonVisibility,
      } = space.visibility;
      const launchingSettingString = `&play=${autoplay ? 1 : 0}&help=${
        help ? 1 : 0
      }&nt=${newTab ? 1 : 0}&qs=${quickstart ? 1 : 0}&hl=${
        highlightReelLaunching ? 1 : 0
      }&ts=${guidedTourDelay}`;
      const guidedToursSettingStrind = `&kb=${pan ? 1 : 0}&lp=${
        loop ? 1 : 0
      }&title=0&tourcta=${callToAction ? 1 : 0}`;
      const userInterfaceSettingString = `&f=${floorsEnabled ? 1 : 0}&nozoom=${
        zoomDisabled ? 1 : 0
      }&wh=${scrollWheel ? 1 : 0}`;
      const visibilitySettingString1 = `&brand=0&dh=${
        dollhouseViewVisibility ? 1 : 0
      }&fp=${floorplanVisibility ? 1 : 0}&gt=${
        guidedTourButtonsVisibility ? 1 : 0
      }&hr=${highlightReelVisibility ? 1 : 0}`;
      const visibilitySettingString2 = `&mls=${
        removeInfoPanelVisibility ? 2 : 1
      }&mt=${poisVisibility ? 1 : 0}&pin=${pinsVisibility ? 1 : 0}&portal=${
        portalVisibility ? 1 : 0
      }&vr=${vrButtonVisibility ? 1 : 0}`;
      const startLocation = `${
        space?.additionalposition !== null ? space?.additionalposition : ""
      }`;
      return (
        launchingSettingString +
        guidedToursSettingStrind +
        userInterfaceSettingString +
        visibilitySettingString1 +
        visibilitySettingString2
      );
    }
  };

  // for renderPoi
  useEffect(() => {
    if (space !== null && showCase !== null && listPoi?.length > 0) {
      showCase.Tag.openTags.subscribe({
        prevState: {
          hovered: null,
          docked: null,
          selected: null,
        },
        onChanged(newState) {
          if (newState.hovered !== this.prevState.hovered) {
            if (newState.hovered) {
              //was hovered
              let find = listPoi.find(
                (poi) => poi.matterPortId === newState?.hovered
              );
              if (find?.mouseAction) {
                removeActive();
                setCurrentPoi(find);
              }
            } else {
              // is no longer hovered
            }
          }
          /*if (newState.docked !== this.prevState.docked) {
            if (newState.docked) {
              console.log(newState.docked, 'was docked');
            } else {
              console.log(this.prevState.docked, 'was undocked');
            }
          }*/

          // only compare the first 'selected' since only one tag is currently supported
          const [selected = null] = newState.selected; // destructure and coerce the first Set element to null
          if (selected !== this.prevState.selected) {
            if (selected) {
              let find = listPoi.find((poi) => poi.matterPortId === selected);
              removeActive();
              setCurrentPoi(find);
              //console.log(selected, 'was selected');
            } else {
              // setCurrentPoi(null)
              // was deselected
            }
          }

          // clone and store the new state
          this.prevState = {
            ...newState,
            selected,
          };
        },
      });
    }
  }, [showCase, listPoi, showCase]);

  return (
    <div
      className={classNames({
        space: true,
        "space--hide":
          !menu &&
          !(content?.text === "3D" || content?.text === "Measurements"),
      })}
    >
      <div className="space__iframe-container" ref={ref}>
        {currentPoi && (
          <ModalPoint
            hideAdd={true}
            hide={!isMainFrame}
            setPoi={setCurrentPoi}
            poi={currentPoi}
          />
        )}
        {!isOpen && (
          <div
            id="iframe-controls"
            className={classNames({
              "iframe-space__hide": isMainFrame,
              "iframe-space__show": !isMainFrame,
            })}
          >
            <MinimapSpaceBtn
              onClick={handleMain}
              className="minimap-space__btn"
              color={color}
            >
              <Replace />
            </MinimapSpaceBtn>
            <MinimapSpaceBtn
              onClick={handleOpenMinimap}
              className="minimap-space__btn"
              color={color}
            >
              <Plus style={{ transform: "rotate(45deg)" }} />
            </MinimapSpaceBtn>
          </div>
        )}
        <iframe
          onMouseEnter={() => {
            // dont have acces to trigger in css to : hover because not parent
            let getWidth = window.innerWidth;
            let getId = document.getElementById("showcase-iframe");
            if (
              getWidth > 968 &&
              getId.classList.contains("space__mini-iframe")
            ) {
              getId.classList.add("space__mini-iframe--hover");
              // let controls = document
              //   .getElementById('iframe-controls')
              //   .classList.add('iframe-space__show--hover');
            }
          }}
          onMouseLeave={(event) => {
            // dont have acces to trigger in css to : hover because not parent
            let getId = document.getElementById("showcase-iframe");
            //Check if we hover not button to control mini map
            let targetWhenLeave = event?.relatedTarget?.classList?.contains(
              "minimap-space__btn"
            )
              ? true
              : false;
            if (
              getId.classList.contains("space__mini-iframe") &&
              !targetWhenLeave
            ) {
              getId.classList.remove("space__mini-iframe--hover");
              // let controls = document
              //   .getElementById('iframe-controls')
              //   .classList.remove('iframe-space__show--hover');
            }
          }}
          style={{
            backgroundColor: "grey",
            cursor: "pointer",
          }}
          className={classNames({
            space__iframe: true,
            "space__mini-iframe": !isMainFrame,
            "space__iframe--hide": isOpen && !isMainFrame,
            space__small_height: !isOpen,
          })}
          src={`${
            space?.additionalposition
              ? space?.url + space?.additionalposition
              : space?.url
          }&search=0&applicationKey=${matterportSdkKey}${spaceUrlCreator()}`}
          frameBorder="0"
          allow="xr-spatial-tracking;"
          id="showcase-iframe"
        ></iframe>
        <div
          className={classNames({
            "minimap-space": isMainFrame,
            "minimap-space-full": !isMainFrame,
            "minimap-space-hide": isOpen && isMainFrame,
          })}
        >
          <div
            className={classNames({
              "minimap-space__controls": isMainFrame,
              "minimap-space__controls--hide": !isMainFrame,
              "minimap-space__controls--hide-big": isOpen && !isMainFrame,
            })}
          >
            <MinimapSpaceBtn
              onClick={handleMain}
              className="minimap-space__btn"
              color={color}
            >
              <Replace />
            </MinimapSpaceBtn>
            <MinimapSpaceBtn
              onClick={handleOpenMinimap}
              className="minimap-space__btn"
              color={color}
            >
              <Plus style={{ transform: "rotate(45deg)" }} />
            </MinimapSpaceBtn>
          </div>
          <div className="minimap-space__image-content">
            <TransformWrapper
              initialScale={1}
              minScale={0.3}
              limitToBounds={false}
              disablePadding={true}
              initialPositionX={0}
              initialPositionY={0}
              ref={zoomRef}
            >
              {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
                <React.Fragment>
                  <div className="tools">
                    <MinimapSpaceBtn
                      className="minimap-space__btn minimap-space__btn--flex"
                      onClick={() => zoomIn()}
                      color={color}
                    >
                      <ZoomIn />
                    </MinimapSpaceBtn>
                    <MinimapSpaceBtn
                      className="minimap-space__btn minimap-space__btn--flex"
                      onClick={() => zoomOut()}
                      color={color}
                    >
                      <ZoomOut />
                    </MinimapSpaceBtn>
                    <MinimapSpaceBtn
                      className="minimap-space__btn minimap-space__btn--flex"
                      ref={resetZoom}
                      onClick={() => {
                        resetTransform();
                      }}
                      color={color}
                    >
                      <Reset />
                    </MinimapSpaceBtn>
                  </div>
                  <TransformComponent>
                    <img
                      src={minimapImg?.preview || mapImg}
                      alt="miniMap"
                      className="minimap-space__img"
                    />
                    {(minimapImg?.preview || mapImg) && (
                      <div
                        style={{
                          transform: `translate(${
                            findFloor?.setting?.posWidth || 0
                          }%, ${findFloor?.setting?.posHeight || 0}%)`,
                        }}
                        className={classNames({
                          "minimap-sweeps": true,
                          "minimap-sweeps--small": isMainFrame,
                        })}
                      >
                        {floorSweeps?.map((sweep, index) => {
                          return (
                            <span
                              key={sweep.sid || index}
                              onClick={(e) => gotToSweep(sweep)}
                              className={classNames({
                                "minimap-sweep": true,
                                "minimap-sweep--small": isMainFrame,
                              })}
                              style={{
                                left: `${sweep.px.right}`,
                                bottom: `${sweep.px.bottom}`,
                              }}
                            ></span>
                          );
                        })}
                        {floorPoi?.map((poi, index) => {
                          return (
                            <React.Fragment key={poi.id || index}>
                              <CustomTooltip
                                isMainFrame={isMainFrame}
                                poi={poi}
                                goToPoi={goToPoi}
                                currPoi={currentPoi}
                              />
                            </React.Fragment>
                          );
                        })}
                        {pose !== null && (
                          <Arrow
                            className={classNames("minimap-space__anchor", {
                              "minimap-space__anchor--big": !isMainFrame,
                            })}
                            style={{
                              transform: `rotate(${pose?.rotation}deg)`,
                              left: `${pose.left}`,
                              bottom: `${pose.bottom}`,
                            }}
                          />
                        )}
                      </div>
                    )}
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </div>
        {isOpen && (
          <button
            disabled={!mapImg && !mapImg?.preview}
            onClick={handleOpenMinimap}
            className="space-show-minimap"
            data-tooltip-content={t("tooltips.showMinimap")}
          >
            <Map />
          </button>
        )}
        {isOpen && space?.userInterface?.floorsEnabled && (
          <button
            onClick={() => setFloorShow((prev) => !prev)}
            className={showMinimapClassnames}
            data-tooltip-content={t(
              floorShow ? "tooltips.hideFloor" : "tooltips.showFloor"
            )}
          >
            {showMinimapClassnames.includes("active") ? (
              <Floor fill={color} />
            ) : (
              <Floor />
            )}
          </button>
        )}
        {isSpaceLoad && floorShow && floor && floor?.length > 0 && (
          <div className="space__wrapper_floor">
            {floor
              .sort((a, b) => b.floor - a.floor)
              .map((item) => {
                return (
                  <SpaceFloor
                    key={item.id || item.floor}
                    className={classNames({
                      space_floor: true,
                      "space_floor--active": item.active,
                    })}
                    onClick={(e) => {
                      handleChangeFloor(e, item);
                    }}
                    color={color}
                  >
                    {item.floor}
                  </SpaceFloor>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Space;
